<template>
  <div class="min-h-screen flex flex-col justify-between">
    <div class="bg-gray-900 py-4 px-16 w-full gap-8 shadow-lg">
      <div class="container mx-auto flex items-center"
           :class="[$route.name !== 'GlimpseManagerLogin' ? 'justify-between' : 'justify-center']">
        <video src="/img/glimpse/CODWIN_LOGO_CONVERTED.mp4" class="h-16" autoplay loop muted></video>

        <div class="flex items-center gap-3">
          <span v-if="$root.$data.barSigned && $route.name !== 'GlimpseManagerLogin'">Welcome, {{ $root.$data.barSigned.name }}</span>
          <router-link :to="{name: 'GlimpseManagerLogin'}" v-if="$route.name !== 'GlimpseManagerLogin'">Logout</router-link>
        </div>
      </div>
    </div>

    <router-view :key="$route.path"></router-view>

    <div class="bg-gray-900 px-4 py-1 w-full flex items-center justify-end shadow-lg">
      <span class="text-white text-xs font-semibold uppercase">Cristaltec Entertainment @{{ new Date().getFullYear()}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Layout"
}
</script>

<style scoped>

</style>
